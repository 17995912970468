import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequesterService } from 'src/app/common/services/requester.service';
import { countries } from "src/app/common/services/country.data";
import { currencies } from "src/app/common/services/currencies";
import { ConfigurationService } from 'src/app/common/services/configuration.service';
import { LoadMoreBankModalComponent } from '../load-more-bank-modal/load-more-bank-modal.component';
import { LoadMoreAccreditationModalComponent } from '../load-more-accreditation-modal/load-more-accreditation-modal.component';
import { LoadMoreUserModalComponent } from '../load-more-user-modal/load-more-user-modal.component';
import { ProfileModalComponent } from '../profile-modal/profile-modal.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AiVendorProfileComponent } from '../ai-vendor-profile/ai-vendor-profile.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { VendorTeamMembersComponent } from '../vendor-team-members/vendor-team-members.component';


@Component({
  selector: 'app-corporate-profile',
  templateUrl: './corporate-profile.component.html',
  styleUrls: ['./corporate-profile.component.scss']
})
export class CorporateProfileComponent implements OnInit {
  @Input() fromDrawer: boolean = false;
  gridStyle = {
    width: "25%",
    textAlign: "center",
  };
  companyData: any;
  data ={};
  countryWisePorts: any = {};
  countryWiseRegions: any = {};
  @Input() companyId: string = '';
  profileLoading = false;
  currencyName: any;
  listOfCountry = countries;
  listOfCurrency = currencies;
  userDetails: any;
  countryName: any;
  ports: any[] = [];
  users: any[] = [];
  mediaBaseUrl = ConfigurationService.mediaBaseUrl;

  serviceCopyClicked = false;
  equipmentCopyClicked = false;
  brandCopyClicked = false;
  partCopyClicked = false;

  websiteForm: FormGroup;
  websiteModalVisible = false;
  isWebsiteUpdating = false;
  syncEnabled = false;
  syncNewVendor = false;
  syncModalVisible = false;
  isSyncing = false;
  syncData: any = {};

  onServiceCopyClick(): void {
    this.serviceCopyClicked = true;

    const jsonString = this.companyData?.SERVICE?.serviceTypes ? JSON.stringify(this.companyData?.SERVICE?.serviceTypes, null, 2) : '';
    navigator.clipboard.writeText(jsonString);
    setTimeout(() => {
      this.serviceCopyClicked = false;
    }, 2000);
  }

  onEquipmentCopyClick(): void {
    this.equipmentCopyClicked = true;

    const jsonString = this.companyData?.SERVICE?.equipmentTypes ? JSON.stringify(this.companyData?.SERVICE?.equipmentTypes, null, 2) : '';
    navigator.clipboard.writeText(jsonString);
    setTimeout(() => {
      this.equipmentCopyClicked = false;
    }, 2000);
  }

  onBrandCopyClick(): void {
    this.brandCopyClicked = true;

    const jsonString = this.companyData?.SERVICE?.brands ? JSON.stringify(this.companyData?.SERVICE?.brands, null, 2) : '';
    navigator.clipboard.writeText(jsonString);
    setTimeout(() => {
      this.brandCopyClicked = false;
    }, 2000);
  }

  onPartCopyClick(): void {
    this.partCopyClicked = true;

    const jsonString = this.companyData?.SERVICE?.parts ? JSON.stringify(this.companyData?.SERVICE?.parts, null, 2) : '';
    navigator.clipboard.writeText(jsonString);
    setTimeout(() => {
      this.partCopyClicked = false;
    }, 2000);
  }

  constructor(private requesterService: RequesterService,
    private router: Router,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
    private activatedRoute: ActivatedRoute,
    private msg: NzMessageService,
    private nzDrawer: NzDrawerService,
    private fb: FormBuilder
  ) {
    if(this.activatedRoute.snapshot.paramMap.get('companyId')) {
      this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    }
    this.websiteForm = this.fb.group({
      websiteUrl: [null, [Validators.required]],
    });
  }

  closeWebsiteModal(openAiGeneratedProfile = true) {
    this.websiteModalVisible = false;
    if (openAiGeneratedProfile) {
      this.openAiGeneratedProfile(true);
    }
  }

  openWebsiteModal() {
    this.websiteModalVisible = true;
  }

  async onWebsiteUpdate() {

    if (!this.websiteForm.value.websiteUrl) {
      this.msg.error('Please enter a valid website URL');
      return;
    }
    this.isWebsiteUpdating = true;

    try {
      await this.requesterService.request('post', `vendor-companies/update-website`, {
        'websiteUrl': this.websiteForm.get('websiteUrl').value,
        company: this.companyId
      }).toPromise();
      this.msg.success('Website URL updated successfully');
      this.getProfileData();
      setTimeout(() => {
        this.closeWebsiteModal();
        this.isWebsiteUpdating = false;
      }, 2000)

    } catch (error) {
      this.msg.error('Failed to update website URL');
      this.isWebsiteUpdating = false;
    }

  }

  ngOnInit(): void {
    this.getProfileData();
    this.getCompanyWiseUser();
    this.getSyncCapability();
  }

  get hasRegionsByCountry() {
    return !!Object.keys(this.countryWiseRegions).length
  }

  getProfileData() {
    this.profileLoading = true;
    this.requesterService.request('get', 'vendor-companies/profile/' + this.companyId).subscribe(data => {
      this.companyData = data.data;
      if (this.companyData?.GENERAL?.invoiceCurrency) {
        const currencyObj = this.listOfCurrency.find(
          (currency) =>
            currency.code === this.companyData?.GENERAL?.invoiceCurrency
        );
        this.currencyName = currencyObj ? currencyObj.name : null;
      }

      if (this.companyData?.GENERAL?.ports) {
        // Grouping ports by country
        this.countryWisePorts = this.companyData?.GENERAL?.ports.reduce(
          (acc, port) => {
            if (!acc[port.country]) {
              acc[port.country] = [];
            }
            acc[port.country].push(port.name);
            return acc;
          },
          {}
        );
      }
      if (this.companyData?.GENERAL?.portCountries) {
        for (const pCountry of this.companyData.GENERAL.portCountries) {
          this.countryWisePorts[pCountry] = [];
        }
      }

      if (this.companyData?.GENERAL?.portRegions) {
        this.countryWiseRegions = this.companyData?.GENERAL?.portRegions.reduce(
          (acc, port) => {
            if (!acc[port.country]) {
              acc[port.country] = [];
            }
            acc[port.country].push(port);
            return acc;
          },
          {}
        );
      }


      // Open General if invoice currency is not updated
      // if (!this.companyData?.PROFILE?.basicDetails?.address) {
      //   this.createAndUpdate('Update Company', this.companyData?.PROFILE)
      // }
      this.profileLoading = false;
      this.getSyncCapability();
    });

  }

  getCompanyWiseUser(): void {
    this.requesterService
      .request("get", "users/vendors/company-wise-users", { company: this.companyId })
      .subscribe((res) => {
        this.users = res.data;
      });

  }

  loadMoreBank(bank: any): void {
    const width = this.getModalWidth();
    const companyId = this.activatedRoute.snapshot.params['companyId'];
    this.modal.create({
      nzTitle: `Bank`,
      nzContent: LoadMoreBankModalComponent,
      //nzWidth: "60%",
      nzWidth: width,
      nzComponentParams: {
        companyId: companyId,
      },
      nzBodyStyle: {
        maxHeight: this.getModalMaxHeight(),
        overflowY: "auto",
      },
    });
  }

  loadMoreAccreditation(accreditation: any): void {
    const width = this.getModalWidth();
    const companyId = this.activatedRoute.snapshot.params['companyId'];
    this.modal.create({
      nzTitle: `Accreditation`,
      nzContent: LoadMoreAccreditationModalComponent,
      nzWidth: width,
      nzComponentParams: {
        companyId: companyId,
      },
      nzBodyStyle: {
        maxHeight: this.getModalMaxHeight(),
        overflowY: "auto",
      },
    });
  }

  loadMoreUser(user: any): void {
    const width = this.getModalWidth();
    const companyId = this.activatedRoute.snapshot.params['companyId'];
    this.modal.create({
      nzTitle: `Users`,
      nzContent: LoadMoreUserModalComponent,
      nzWidth: width,
      nzComponentParams: {
        companyId: companyId,
      },
      nzBodyStyle: {
        maxHeight: this.getModalMaxHeight(),
        overflowY: "auto",
      },
      nzFooter: null,
    });
  }

  createAndUpdate(argument: string, data?: any): void {
    let dataToSend: any = {
      argument: argument,
      value: data ? data : null,
    };
    const width = this.getModalWidth();
    const modalRef = this.modal.create({
      nzTitle: `${argument}`,
      nzContent: ProfileModalComponent,
      nzMaskClosable: false,
      //  nzWidth: "60%",
      nzWidth: width,
      nzComponentParams: {
        data: dataToSend,
      },
      nzBodyStyle: {
        maxHeight: this.getModalMaxHeight(),
        overflowY: "auto",
      },


      nzOnOk: (componentInstance) => {
        console.log("componentInstance", componentInstance);
        if (componentInstance.companyDetails) {
          const form = componentInstance.companyDetailsForm;
          if (form.invalid) {
            Object.values(form.controls).forEach((control) => {
              control.markAsTouched();
              control.updateValueAndValidity();
            });
            return false;
          }

          switch (argument) {
            case "Update Company":
              this.addUpdateCompanyDetails(
                componentInstance.companyDetailsForm.value
              );
              this.msg.success("Company Details updated successfully");

              break;

            default:
              console.warn("Unsupported operation:", argument);
              break;
          }
        }

        if (componentInstance.generalInfoDetails) {
          const form = componentInstance.generalInfoForm;
          if (form.invalid) {
            Object.values(form.controls).forEach((control) => {
              control.markAsTouched();
              control.updateValueAndValidity();
            });
            return false;
          }

          switch (argument) {
            case "Service Area":
              this.addUpdateGeneralDetails(
                componentInstance.generalInfoForm.value
              );
              this.msg.success("General Information updated successfully");
              break;

            default:
              console.warn("Unsupported operation:", argument);
              break;
          }
        }

        if (componentInstance.serviceAndEquipmentDetails) {
          console.log("componentInstance.serviceAndEquipmentDetails", componentInstance.serviceAndEquipmentDetails,);
          const form = componentInstance.serviceAndEquipmentForm;
          console.log("form", form.value);

          switch (argument) {
            case "Equipment, Brands & Parts":
              if (
                 form.get('parts')?.value?.length > 0 ||
                 form.get('equipments')?.value?.length > 0 ||
                 form.get('brands')?.value?.length > 0
              ) {
                this.addUpdateService(componentInstance.serviceAndEquipmentForm.value);
                break;
              } else {
                console.log("No data provided for service, equipment, or brand.");
                this.msg.error("Please select at least one service type");
                return false; // Ensure the modal does not close
              }

            default:
              console.warn("Unsupported operation:", argument);
              break;
          }
        }

        if (componentInstance.bankDetails) {
          const form = componentInstance.bankForm;
          if (form.invalid) {
            Object.values(form.controls).forEach((control) => {
              control.markAsTouched();
              control.updateValueAndValidity();
            });
            return false;
          }

          switch (argument) {
            case "Update Bank":
              // this.addUpdateBankDetails(form.value);
              this.msg.success("Bank details updated successfully");
              break;

            case "Add Bank":
              delete form.value._id;
              // this.addUpdateBankDetails(form.value);
              this.msg.success("Bank details added successfully");
              break;

            default:
              console.warn("Unsupported operation:", argument);
              break;
          }
        }

        if (componentInstance.accreditationDetails) {
          const form = componentInstance.accreditationForm;

          if (form.invalid) {
            Object.values(form.controls).forEach((control) => {
              control.markAsTouched();
              control.updateValueAndValidity();
            });
            return false;
          }

          switch (argument) {
            case "Update Accreditation":
              this.addUpdateAccreditation(form.value);
              this.msg.success("Accreditation details updated successfully");
              break;

            case "Add Accreditation":
              delete form.value._id;
              this.addUpdateAccreditation(form.value);
              this.msg.success("Accreditation details added successfully");
              break;

            default:
              console.warn("Unsupported operation:", argument);
              break;
          }
        }
      },    });

  }

  // Add Or Update bank details
  addUpdateBankDetails(data: any): void {
    this.requesterService
      .request("post", `vendor-companies/update-bank-details`, data)
      .subscribe((res) => {
        this.getProfileData();
      });
  }

  // Remove Bank details
  removeBankDetails(id: string): void {
    this.requesterService
      .request("post", `vendor-companies/update-bank-details`, {
        _id: id,
        isDeleted: true,
      })
      .subscribe((res: any) => {
        this.getProfileData();
      });
  }

  // Add or update accreditation details
  addUpdateAccreditation(data: any): void {
    this.requesterService
      .request("post", `vendor-companies/update-certificates`, data)
      .subscribe((res: any) => {
        this.getProfileData();
      });
  }

  // Remove Accreditation
  removeAccreditation(item: any): void {
    const finalObj = {
      isDeleted: true,
      _id: item._id,
      certification: item.certification,
      files: item.files,
    };

    this.requesterService
      .request("post", `vendor-companies/update-certificates`, finalObj)
      .subscribe((res: any) => {
        this.msg.success("Accreditation removed successfully");
        this.getProfileData();
      });
  }

  addUpdateService(data: any): void {
    let finalObj = {
      section: "SERVICE",
      serviceTypes: data.serviceName,
      equipmentTypes: data.equipments,
      brands: data.brands,
      parts: data.parts,
      company: this.companyId,
    };


      this.requesterService
        .request("post", `vendor-companies/update-section`, finalObj)
        .subscribe((res) => {
          this.msg.success("Service details updated successfully");
          this.getProfileData();
        });

  }




  addUpdateGeneralDetails(data: any): void {
    let finalObj = {
      section: "GENERAL",
      ports: data.ports ? data.ports : [],
      portCountries: data.portCountries ? data.portCountries : [],
      portRegions: data.portRegions ? data.portRegions: [],
      company: this.companyId,
    };
    this.requesterService
      .request("post", `vendor-companies/update-section`, finalObj)
      .subscribe((res) => {
        this.getProfileData();
      });
  }

  addUpdateCompanyDetails(data: any): void {
    let finalObj = {
      section: "PROFILE",
      companyName: data.companyName,
      address: data.address,
      countryName: data.countryName,
      zipCode: data.zipCode,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      companyLogo: data?.companyLogo ? data.companyLogo : null,
      invoiceCurrency: data.invoiceCurrency,
      websiteUrl: data?.websiteUrl ? data.websiteUrl : '',
      company: this.companyId,
    };
    this.requesterService
      .request("post", `vendor-companies/update-section`, finalObj)
      .subscribe((res) => {
        this.getProfileData();
      });
  }

  get isServiceEquipmentBrandEmpty(): boolean {
    return (
      // !this.companyData?.SERVICE?.serviceTypes?.length &&
      !this.companyData?.SERVICE?.equipmentTypes?.length &&
      !this.companyData?.SERVICE?.brands?.length &&
      !this.companyData?.SERVICE?.parts?.length);
  }

  handleDownload(img: string): void {
    const imageUrl = `${this.mediaBaseUrl}${img}`;

    // Open image in a new tab
    const newTab = window.open(imageUrl, '_blank');
    if (!newTab) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }


  }

  manageUsers(): void {
    // this.router.navigate([`/main/vendors/${this.companyData.PROFILE._id}/team-members`]);
    const drawer = this.nzDrawer.create({
      nzTitle: 'Manage Team Members',
      nzContent: VendorTeamMembersComponent,
      nzWidth: '80%',
      nzContentParams: {
        companyId: this.companyId,
        fromDrawer: true
      }
    }).afterClose.subscribe(afterClose => {
      this.getCompanyWiseUser();
    })
  }
  getModalWidth(): string {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1440) {
      return "50%"; // For large screens
    } else if (screenWidth >= 1024) {
      return "60%"; // For desktop screens
    } else if (screenWidth >= 768) {
      return "80%"; // For tablet screens
    } else {
      return "90%"; // For small screens/mobile devices
    }
  }
  // Helper method to calculate max height based on screen size
  private getModalMaxHeight(): string {
    const screenHeight = window.innerHeight;

    // Set a max height based on the screen size (e.g., 80% of screen height)
    if (screenHeight > 800) {
      return "400px"; // For large screens, set a fixed max height
    } else {
      return "80vh"; // For smaller screens, use a percentage of the viewport height
    }
  }



  openAiGeneratedProfile(generate: boolean = false): void {
    // First check if website is available
    if (!this.companyData.PROFILE.websiteUrl) {
      // Open a modal to prompt the user to enter the website URL
      this.openWebsiteModal();
      return;
    }


    this.nzDrawer.create({
      nzTitle: 'AI Generated Profile',
      nzContent: AiVendorProfileComponent,
      nzWidth: 600,
      nzContentParams: {
        data: {
          argument: 'AI Generated Profile',
          companyId: this.companyId,
          companyName: this.companyData.PROFILE?.companyName,
          website: this.companyData.PROFILE?.websiteUrl,
          value: this.companyData.PROFILE?.aiGeneratedProfile,
          serviceTypes: this.companyData.SERVICE?.serviceTypes,
          equipmentTypes: this.companyData.SERVICE?.equipmentTypes,
          brands: this.companyData.SERVICE?.brands,
          parts: this.companyData.SERVICE?.parts,
          generate: generate
        }
      }
    }).afterClose.subscribe((res) => {
      if (res) {
        this.getProfileData();
      }
    } );
  }

  getSyncCapability(): void {
    this.requesterService.request('get', `vendor-companies/sync-capability`).subscribe((res) => {
      // this.notificationService.success('Sync Capability', 'Sync Capability is enabled');
      this.syncEnabled = res.data.enabled;
      if(this.syncEnabled) {
        this.getSyncCapabilityForVendor();
      }
    });
  }

  getSyncCapabilityForVendor(): void {
    this.requesterService.request('get', `vendor-companies/sync-capability/${this.companyId}`).subscribe((res) => {
      // this.notificationService.success('Sync Capability', 'Sync Capability is enabled');
      this.syncNewVendor = !res.data.existingVendor;
      this.syncData = res.data;
    }, err => {
      this.notificationService.error('Sync Capability', err.error.message);
    });
  }

  onSyncWithProduction(): void {
    this.isSyncing = true;
    this.requesterService.request('post', `vendor-companies/sync-vendor`, { company: this.companyId }).subscribe((res) => {
      this.notificationService.success('Sync Successful', 'Synced with production successfully');
      this.isSyncing = false;
      this.syncModalVisible = false;
      this.getProfileData();
    }, err => {
      this.notificationService.error('Sync Error', err.error.message);
      this.isSyncing = false;
    });
  }

  toggleOEM(): void {

    this.requesterService.request('post', `vendor-companies/toggle-oem-status`, { company: this.companyId, isOEM: !this.companyData?.PROFILE?.isOEM }).subscribe((res) => {
      this.notificationService.success('OEM Status', 'OEM status updated successfully');
      this.getProfileData();
    } , err => {
      this.notificationService.error('OEM Status', err.error.message);
    } );

  }

  toggleOnlineVendor(): void {
    this.requesterService.request('post', `vendor-companies/toggle-online-vendor-status`, { company: this.companyId, isOnlineVendor: !this.companyData?.PROFILE?.isOnlineVendor }).subscribe((res) => {
      this.notificationService.success('Online Vendor Status', 'Online Vendor status updated successfully');
      this.getProfileData();
    } , err => {
      this.notificationService.error('Online Vendor Status', err.error.message);
    } );
  }

  onChangeProfileNarrative(narrative: string): void {
    this.requesterService.request('post', `vendor-companies/update-profile-narrative`, { company: this.companyId, profileNarrative: narrative }).subscribe((res) => {
      // this.notificationService.success('Profile Narrative', 'Profile narrative updated successfully');
      // if changed then show notification
      if(this.companyData.PROFILE.profileNarrative !== narrative) {
        this.notificationService.success('Profile Narrative', 'Profile narrative updated successfully');
      }
      this.companyData.PROFILE.profileNarrative = narrative;
    } , err => {
      this.notificationService.error('Profile Narrative', err.error.message);
    } );
  }

  toggleStatus(): void {
    this.requesterService.request('put', `vendor-companies/${this.companyId}/toggle-status`).subscribe((res) => {
      this.notificationService.success('Company Status', 'Company status updated successfully');
      this.getProfileData();
    } , err => {
      this.notificationService.error('Company Status', err.error.message);
    } );
  }


}
