<div style="display: flex; flex-direction: column; align-items: center;">
  <h5>
    {{data?.companyName}}
  </h5>
  <p nz-typography>
    <span nz-icon nzType="global" style="margin-right: 8px;"></span>
    {{data?.website}}
  </p>
  <button nz-button *ngIf="!manual" [nzLoading]="isGenerating" nzType="primary" nzShape="round" (click)="generateAIProfile(true)">
    {{isGenerating ? 'Generating' : generatedDAta ? 'Re-Generate' : 'Generate'}} AI Profile</button>
</div>

<p nz-typography *ngIf="!generatedDAta && generated && !manual" style="color: red; text-align: center; margin-top: 12px;">
  We are unable to generate AI Profile for this vendor. Website may be down or not crawlable.

</p>
<p *ngIf="!manual" style="text-align: center; margin-top: 12px;">
  <button nz-button nzType="primary" nzShape="round" (click)="manual = true" *ngIf="!manual">
    Try by entering details
  </button>
</p>

<nz-card *ngIf="manual" [nzExtra]="closeButton" nzTitle="Enter Details Manually">
  <ng-template #closeButton>
    <button nz-button nzType="link" (click)="manual = false">
      <span nz-icon nzType="close"></span>
    </button>
  </ng-template>
  <!--Big Text area to put details and a button to analyze-->
  <textarea nz-input nzSize="large" [(ngModel)]="manualData" placeholder="Enter details here" style="height: 200px;"></textarea>
  <br/>
  <br/>
  <button style="float: right;" nz-button nzType="primary" nzShape="round" (click)="generateAIProfile(true, true)" [nzLoading]="isGenerating">
    Analyze
  </button>
</nz-card>

<div *ngIf="generated && generatedDAta">

  <!-- <p nz-typography>
    {{generatedDAta?.company_profile}}
  </p> -->
  <!-- <nz-divider nzText="Service Types">

  </nz-divider>
  <nz-tag nzColor="green" *ngFor="let serviceType of generatedDAta?.service_types; let i= index" nzMode="closeable" (nzOnClose)="removeItem('service_types', i)">{{serviceType}}</nz-tag>
  <nz-tag *ngIf="!inputVisible['service_types']" class="editable-tag" nzNoAnimation (click)="showInput('service_types')">
    <span nz-icon nzType="plus"></span>
    New Service
  </nz-tag>
  <input
      #inputServiceTypes
      nz-input
      nzSize="small"
      *ngIf="inputVisible['service_types']"
      type="text"
      [(ngModel)]="inputValue['service_types']"
      style="width: 78px;"
      (blur)="handleInputConfirm('service_types')"
      (keydown.enter)="handleInputConfirm('service_types')"
    /> -->
  <nz-divider nzText="Equipment Types / Services">

  </nz-divider>
  <nz-tag nzColor="purple" *ngFor="let equipmentType of generatedDAta?.equipment_types; let i= index" nzMode="closeable" (nzOnClose)="removeItem('equipment_types', i)">{{equipmentType}}</nz-tag>
  <nz-tag *ngIf="!inputVisible['equipment_types']" class="editable-tag" nzNoAnimation (click)="showInput('equipment_types')">
    <span nz-icon nzType="plus"></span>
    New Equipment
  </nz-tag>
  <input
      #inputEquipmentTypes
      nz-input
      nzSize="small"
      *ngIf="inputVisible['equipment_types']"
      type="text"
      [(ngModel)]="inputValue['equipment_types']"
      style="width: 78px;"
      (blur)="handleInputConfirm('equipment_types')"
      (keydown.enter)="handleInputConfirm('equipment_types')"
    />
  <nz-divider nzText="Brands">

  </nz-divider>
  <nz-tag nzColor="orange" *ngFor="let brand of generatedDAta?.brands; let i= index" nzMode="closeable" (nzOnClose)="removeItem('brands', i)">{{brand}}</nz-tag>
  <nz-tag *ngIf="!inputVisible['brands']" class="editable-tag" nzNoAnimation (click)="showInput('brands')">
    <span nz-icon nzType="plus"></span>
    New Brand
  </nz-tag>
  <input
      #inputBrands
      nz-input
      nzSize="small"
      *ngIf="inputVisible['brands']"
      type="text"
      [(ngModel)]="inputValue['brands']"
      style="width: 78px;"
      (blur)="handleInputConfirm('brands')"
      (keydown.enter)="handleInputConfirm('brands')"
    />
  <nz-divider nzText="Part Types">

  </nz-divider>
  <nz-tag nzColor="blue" *ngFor="let partType of generatedDAta?.parts; let i= index" nzMode="closeable" (nzOnClose)="removeItem('parts', i)">{{partType}}</nz-tag>
  <nz-tag *ngIf="!inputVisible['parts']" class="editable-tag" nzNoAnimation (click)="showInput('parts')">
    <span nz-icon nzType="plus"></span>
    New Part
  </nz-tag>
  <input
      #inputParts
      nz-input
      nzSize="small"
      *ngIf="inputVisible['parts']"
      type="text"
      [(ngModel)]="inputValue['parts']"
      style="width: 78px;"
      (blur)="handleInputConfirm('parts')"
      (keydown.enter)="handleInputConfirm('parts')"
    />

    <nz-divider>
    </nz-divider>
    <div style="display: flex; justify-content: center;" *ngIf="generatedDAta">
      <button nz-button nzType="primary" nzShape="round" [nzLoading]="isSaving" (click)="saveAIProfileConfirm()">{{isSaving ? 'Saving' : 'Save'}} AI Profile</button>
    </div>

</div>
